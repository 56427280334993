import React from "react"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import "./contact-us.css"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Phone from "@material-ui/icons/Phone"
import Email from "@material-ui/icons/Email"
import LocationOn from "@material-ui/icons/LocationOn"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 500,
      margin: "auto",
      backgroundColor: "white",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
}))

export default function ContactUs() {
  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title="Contact us"
        description="Contact information for KLAR Solutions."
      />
      <div className="page-body">
        <div className="header">
          <Typography variant="h4" component="h2" color="primary" gutterBottom>
            Contact Us
          </Typography>
        </div>
        <div className="contact-form">
          <Typography variant="body1" gutterBottom>
            <hr />
            <Card variant="outlined" className="contact-card">
              <Typography variant="subtitle1" className="contact-card-subtitle">
                We are located 1.5 hours east of Toronto, Ontario and currrently
                we serve clients spanning from Ottawa to Niagara Region.
              </Typography>
              <Divider />
              <List>
                <ListItem color="secondary" dense="true" divider="true">
                  <ListItemIcon>
                    <Phone color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="888.920.KLAR(5527)"
                    color="secondary"
                    className="tech-item"
                  />
                </ListItem>
                <ListItem color="secondary" dense="true" divider="true">
                  <ListItemIcon>
                    <Email color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="candersson@klarsolutions.com"
                    color="secondary"
                    className="tech-item"
                  />
                </ListItem>
                <ListItem color="secondary" dense="true" divider="true">
                  <ListItemIcon>
                    <LocationOn color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Trent Hills, Ontario"
                    color="secondary"
                    className="tech-item"
                  />
                </ListItem>
              </List>
            </Card>
            <br />
            <hr />

            <Typography variant="h6" color="primary" gutterBottom>
              Send us a message and we will get back to you as soon as possible
            </Typography>
            <hr />
          </Typography>
          <form
            className={classes.root}
            name="simklar-contact"
            method="POST"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="simklar-contact" />
            <div className="contact-form-fields-single">
              <TextField fullWidth type="text" name="name" label="Your Name" />
            </div>
            <div className="contact-form-fields-single">
              <TextField
                fullWidth
                type="email"
                name="email"
                label="Your Email"
              />
            </div>
            <div className="contact-form-fields">
              <FormControl fullWidth className={classes.margin}>
                <TextField
                  name="message"
                  label="Message:"
                  multiline
                  rows="10"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </FormControl>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className="form-button"
            >
              Send
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  )
}
